<template>
    <div>
      <!-- 采购返修单 -->
      <list-search :childData="childData" @onSearch="onSearch" @clearSearch="clearSearch"></list-search>
      <list-menu @buttonClick="buttonClick" :menuData="menudata" ></list-menu>
      <global-table :tableField="tableField"  :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction" @pageChange="pageChange"></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, menudata, tableData } from './js/purchaseRepair.js'
export default {
  components: Component.components,
  name: 'PurchaseRepair',
  data () {
    let pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      menudata: menudata,
      tableData: tableData,
      paginationData: { total: 1, pageSize },
      searchdata: { pageNum: 1, pageSize },
      pageNum: 1,
      pageSize: pageSize
    }
  },
  created () {
    this.requestData(this.searchdata)
  },
  methods: {
    buttonClick: function (data) {
      this.$router.push({ params: { viewName: 'CreatRepair' } })
    },
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'RepairDetail' }, query: { id: data.id } })
      }
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    // 获取返修单列表
    requestData (data) {
      request('/api/repair/repair/queryRepairList', 'POST', data).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          if (this.paginationData.total !== Response.data.total) {
            this.paginationData.total = Response.data.total
          }
        }
      })
    },
    // 查询
    onSearch (data) {
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.endDate = dayjs(data.createDate[1]).format('YYYY-MM-DD 23:59:59')
        this.searchdata.startDate = this.dateFormate(data.createDate[0])
      }
      this.searchdata.pageNum = 1
      this.searchdata.pageSize = this.pageSize
      this.requestData(this.searchdata)
    },
    // 清空
    clearSearch (data) {
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      this.searchdata.pageNum = 1
      this.searchdata.pageSize = this.pageSize
      this.requestData(this.searchdata)
    },
    // 分页
    pageChange (data) {
      this.searchdata.pageNum = data
      this.requestData(this.searchdata)
    }
  }

}

</script>
<style scoped lang="scss">

</style>
