import md5 from 'js-md5'
export var SearchList = [
  { labe: '返修单号', code: 'ordersCode', type: 'input' },
  { labe: '公司名称', code: 'companyCodeName', type: 'input', placeholder: '编码/名称' },
  { labe: '供应商', code: 'supplierNameCode', type: 'input', placeholder: '编码/名称' },
  {
    labe: '创建日期',
    code: 'createDate',
    type: 'daterange'
  },
  {
    labe: '返修单状态',
    code: 'repairState',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待供应商确认', value: '1' },
      { label: '待我修改', value: '2' },
      { label: '待库房发货', value: '3' },
      { label: '待供应商收货', value: '4' },
      { label: '待供应商发货', value: '5' },
      { label: '待库房收货', value: '6' },
      { label: '待我确认', value: '7' },
      { label: '已完成', value: '8' }
    ]
  },
  {
    labe: '返修单类型',
    code: 'repairType',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: 'RMA返修', value: '1' },
      { label: 'DOA返修', value: '2' }
    ]
  }
]
export var SearchData = {
  ordersCode: '',
  companyCodeName: '',
  supplierNameCode: '',
  startDate: '',
  // createDate: '',
  endDate: '',
  repairState: '',
  repairType: ''
}
export var tableField = [
  { label: '返修单号', code: 'repairOrders', type: 'input', width: '' },
  {
    label: '公司名称',
    code: 'companyName',
    type: 'function',
    width: '140px',
    handle (index, row) {
      return `${row.companyCode}-${row.companyName}`
    }
  },
  {
    label: '供应商',
    code: 'supplierName',
    type: 'function',
    width: '140px',
    handle (index, row) {
      return `${row.supplierCode}-${row.supplierName}`
    }
  },
  {
    label: '类型',
    code: 'repairType',
    type: 'function',
    handle: (index, data) => {
      return data.repairType === '1' ? 'RMA返修' : 'DOA返修'
    }
  },
  { label: '创建人', code: 'createUserId', type: 'input', width: '' },
  { label: '创建日期', code: 'createDate', type: 'input', width: '' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '',
    handle: function (index, row) {
      const color = md5(row.status + '').substring(0, 6)
      const label = row.status === '1' ? '待供应商确认'
        : row.status === '2' ? '待我修改'
          : row.status === '3' ? '待库房发货'
            : row.status === '4' ? '待供应商收货'
              : row.status === '5' ? '待供应商发货'
                : row.status === '6' ? '待库房收货'
                  : row.status === '7' ? '待我确认'
                    : row.status === '8' ? '已完成'
                      : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
      // {
      //   actionLabel: '编辑',
      //   operationButton: 'edit',
      //   stateField: 'status',
      //   stateSymbol: '=',
      //   stateValue: '2'
      // }
    ]
  }
]
export var tableData = []

export var menudata = [{
  label: '+创建返修申请',
  action: 'draftContract',
  id: 1
}
]
